<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <div class="image-emp-profile">
              <div class="image-container">
                <div class="image-company">
                  <img :src="showImage" alt="">
                  <div class="image-button">
                    <div class="btn-img">
                      <i class="fas fa-camera"></i>
                      <input @change="PreviewImage" class="file-input" type="file" name="resume">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
                @keydown.enter="focusNext"
                outlined
                dense
                :label="$t('userAdmin.name')"
                v-model="data.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
                @keydown.enter="focusNext"
                outlined
                dense
                :label="$t('userAdmin.email')"
                v-model="data.email"
                :hint="`${server_errors.email}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-select
                @keydown.enter="focusNext"
                outlined
                dense
                :items="listRoleUser"
                multiple
                item-text="name"
                item-value="id"
                :label="$t('userAdmin.role')"
                v-model="data.roleId"
                :hint="`${server_errors.roleId}`"
                persistent-hint
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="12">
            <v-select
                @keydown.enter="focusNext"
                outlined
                dense
                :items="permission"
                multiple
                item-text="name"
                item-value="id"
                :label="$t('userAdmin.permission')"
                v-model="allPermission"
                :hint="`${server_errors.permissionId}`"
                persistent-hint
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="selectAllPermission.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
              {{ $t("userAdmin.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>


export default {
  data() {
    return {
      showPassword: false,
      btnLoading: false,
      showImage: "",
      listRoleUser: [],
      permission: [],
      allPermission: [],
      data: {
        name: "",
        email: "",
        // password: "",
        roleId: "",
        profile: "",
        permissionId: "",
      },
      server_errors: {
        name: "",
        email: "",
        // password: "",
        roleId: "",
        permissionId: "",
      }
    }
  },
  computed: {
    selectAllPermission() {
      return this.allPermission.length === this.permission.length
    },
    selectSomePermission() {
      return this.allPermission.length > 0 && !this.selectAllPermission;
    },
    icon() {
      if (this.selectAllPermission) return 'mdi-close-box'
      if (this.selectSomePermission) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllPermission) {
          this.allPermission = []
        } else {
          this.allPermission = this.permission.slice()
        }
      })
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input,v-select'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    PreviewImage: function (event) {
      const input = event.target;

      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
            'top-right',
            'danger', 3000,
            "image must be jpeg,png"
        )
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append('imageFile', imageFile)
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.data.profile = res.data.filename
        }
      })
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    getRoleUser() {
      this.$axios.get(`role`).then((res) => {
        this.listRoleUser = res.data.data;
      }).catch(() => {
      })
    },
    getPermission() {
      this.$axios.get(`list/permissions`).then((res) => {
        this.permission = res.data.data;
      }).catch((e) => {
        console.log(e)
      })
    },
    saveChange() {
      const formData = new FormData();
      formData.append('name', this.data.name);
      formData.append('email', this.data.email);
      // formData.append('password', this.data.password);
      this.data.roleId.map((item) => {
        formData.append('roleId[]', item);
      });

      if (this.allPermission) {
        this.allPermission.map((item) => {
          formData.append("permissionId[]", item.id || item);
        })
      }
      
      if (this.imageFile) {
        formData.append('profile', this.data.profile);
      }
      this.$axios.post(`admin/add/user`, formData).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
        this.$router.push({name: "userAdmin.index"})
        this.btnLoading = false;
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  created() {
    this.getRoleUser();
    this.getPermission();
  }
}
</script>

<style scoped lang="scss">
.image-emp-profile {
  width: 100%;
  height: 180px;
  margin-top: 10px;

}

.from-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  .from {
    width: 400px;
    height: auto;
    padding-bottom: 60px;

  }
}
</style>
