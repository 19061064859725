<template>
  <div id="create" class="main-container">
    <div class="content mt-6">
      <v-row>
        <v-col cols="4" md="4">
          <v-text-field
            label="Budget"
            outlined
            dense
            :hint="`${server_errors.name}`"
            persistent-hint
            v-model="data.name"
          ></v-text-field>
          <v-textarea
            outlined
            name="input-7-4"
            label="Detail"
            v-model="data.detail"
          ></v-textarea>
          <v-btn class="btn" color="primary" @click="saveData"> Save </v-btn>
        </v-col>
        <v-col cols="8" md="8">
          <table class="table table-view">
            <thead>
              <tr>
                <th class="text-start">No</th>
                <th class="text-left">Budget</th>
                <th class="text-left">Detail</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in expensesData" :key="idx">
                <td>
                  {{ idx + 1 }}
                </td>
                <td>
                  {{ item.et_name }}
                </td>
                <td>
                  {{ item.detail }}
                </td>

                <td class="text-end">
                  <v-menu transition="slide-y-transition" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined v-bind="attrs" v-on="on">
                        <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          class="btn-edit"
                          @click="onEdit(item.id)"
                          >Edit</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          class="btn-delete"
                          @click="onDeleted(item.id)"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <Loading v-if="isLoading" />
      <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
        <template v-slot="{ close }">
          <editComProduct
            :ExpensesItem="ExpensesItem"
            @close="close"
            @success="fetchExpense"
          />
        </template>
      </ModalEdit>

      <ModalDelete>
        <template v-slot="{ close }">
          <deleteComProduct :id="id" @close="close" @success="fetchExpense" />
        </template>
      </ModalDelete>
    </div>
  </div>
</template>

<script>
import editComProduct from "@/views/MainAdmin/CompanyProjectExpenses/type/form/edit";
import deleteComProduct from "@/views/MainAdmin/CompanyProjectExpenses/type/form/delete";
import Loading from "@/components/Loading";
export default {
  components: {
    Loading,
    editComProduct,
    deleteComProduct,
  },
  data() {
    return {
      id: "",
      expensesData: {},
      ExpensesItem: {},
      dialog: false,
      isLoading: false,
      data: {
        name: "",
        detail: "",
      },
      server_errors: {
        name: "",
      },
    };
  },

  methods: {
    saveData() {
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("detail", this.data.detail);
      this.$axios
        .post(`company/expenses-type`, formData)
        .then((res) => {
          if (res.data.error === false) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.isLoading = false;
            this.data = {
              name: "",
              detail: "",
            };
            this.server_errors = {
              name: "",
            };
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },

    filterExpenses(id) {
      return (
        this.expensesData.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },

    onEdit(id) {
      this.ExpensesItem = {
        ...this.filterExpenses(id),
      };
      console.log(this.ExpensesItem);
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(id) {
      this.id = id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchExpense() {
      this.isLoading = true;
      this.$axios
        .get(`company/expenses-type`)
        .then((res) => {
          this.isLoading = false;
          console.log(res);
          if (res.status === 200) {
            this.expensesData = res.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchExpense();
  },
};
</script>

<style>
.content {
  padding: 20px;
}
.btn {
  color: aliceblue !important;
}
</style>