var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container",attrs:{"id":"create"}},[_c('div',{staticClass:"content mt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Budget","outlined":"","dense":"","hint":("" + (_vm.server_errors.name)),"persistent-hint":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"Detail"},model:{value:(_vm.data.detail),callback:function ($$v) {_vm.$set(_vm.data, "detail", $$v)},expression:"data.detail"}}),_c('v-btn',{staticClass:"btn",attrs:{"color":"primary"},on:{"click":_vm.saveData}},[_vm._v(" Save ")])],1),_c('v-col',{attrs:{"cols":"8","md":"8"}},[_c('table',{staticClass:"table table-view"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-start"},[_vm._v("No")]),_c('th',{staticClass:"text-left"},[_vm._v("Budget")]),_c('th',{staticClass:"text-left"},[_vm._v("Detail")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.expensesData),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(idx + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.et_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.detail)+" ")]),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-ellipsis-v btn-ellipsis"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-edit",on:{"click":function($event){return _vm.onEdit(item.id)}}},[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onDeleted(item.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)])])],1),(_vm.isLoading)?_c('Loading'):_vm._e(),_c('ModalEdit',{attrs:{"fullscreen":"","title":"ແກ້ໄຂຂໍ້ມູນ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('editComProduct',{attrs:{"ExpensesItem":_vm.ExpensesItem},on:{"close":close,"success":_vm.fetchExpense}})]}}])}),_c('ModalDelete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('deleteComProduct',{attrs:{"id":_vm.id},on:{"close":close,"success":_vm.fetchExpense}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }